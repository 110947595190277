<template lang="pug">
.catalog-search-saver
  ui-btn(
    v-if="currentUser"
    preset="rounded"
    height=40
    @click="toggleSearchPreset"
  )
    template(v-if="isSaved")
      ui-icon(name="bookmark-search-active--colored" left)
      span Удалить поиск
    template(v-else)
      ui-icon(name="bookmark-search" left)
      span Сохранить поиск

  ui-btn(preset="rounded" height=40 @click="shareSearch")
    ui-icon(name="share" left)
    span Поделиться
</template>

<script>
  import { UiBtn, UiIcon } from '@/uikit';
  import { service as API } from '@/services/assets/assets-service.js';
  import { copyTextToClipboard } from '@/uikit/util/copy-clipboard';
  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('SearchPresetSaver', 'orange');

  export default {
    name: 'catalog-search-saver',
    components: { UiBtn, UiIcon },

    data() {
      return {
        presetId: undefined,
      };
    },

    computed: {
      currentUser() {
        return this.$store.getters['currentUser'];
      },

      searchParams() {
        return this.$store.getters['catalog/search_params'];
      },

      placementPurpose() {
        return this.$store.state['catalog'].placementPurpose;
      },

      isSaved() {
        return !!this.presetId;
      },
    },

    watch: {
      searchParams() {
        this.isSave = false;
      },
    },

    methods: {
      toggleSearchPreset() {
        if (!this.isSaved) {
          this.saveSearch();
        } else {
          this.deleteSearch();
        }
      },

      async saveSearch() {
        try {
          const presetId = await API.saveSearchPreset({
            search: this.searchParams,
            placement_purpose: this.placementPurpose,
          });
          this.presetId = presetId;
          this.$notify.success('Поиск добавлен в избранное');
        } catch (error) {
          if (error && error.data) {
            this.$notify.error(error.data.data?.body?.error, 3000);
          }
          handleError(error, logger);
        }
      },

      async shareSearch() {
        try {
          const shareLink = await API.shareSearchPreset({
            search: this.searchParams,
            placement_purpose: this.placementPurpose,
          });
          this.copyLink(shareLink);
        } catch (error) {
          handleError(error, logger);
        }
      },

      async copyLink(link) {
        try {
          await copyTextToClipboard(link);
          this.$notify.success('Ссылка скопирована в буфер обмена', 2000);
        } catch (error) {
          this.$notify.error('Ошибка копирования в буфер обмена', 2000);
        }
      },

      async deleteSearch() {
        try {
          const ok = await API.deleteSearchPreset(this.presetId);
          if (ok) {
            this.presetId = undefined;
            this.$notify.success('Поиск удален из избранного');
          }
        } catch (error) {
          handleError(error, logger);
        }
      },
    },
  };
</script>

<style lang="scss">
  .catalog-search-saver,
  .catalog-search-share {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: c('black80', 'light');
    cursor: pointer;
    width: 100%;
    height: 40px !important;
    
    .theme--light.btn-rounded.v-btn {
      box-shadow: none;
    }
    &:hover {
      color: c('black100', 'light');
    }

    &-wrap {
      margin: auto 8px 0 auto;
      display: flex;
    }
  }
</style>
